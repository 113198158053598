<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-04-19 08:38:49
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-06-09 17:09:08
-->
<template>
    <div class="info-wrap">
        <div class="title-wrap">
            <el-page-header @back="goBack" content="投票项详情"></el-page-header>
        </div>
        <div class="case-wrap">
            <div class="btn">
                <el-button type="primary" @click="isAddGift = true">添加投票项</el-button>
            </div>
            <el-table v-loading="loading" border :data="tableData" style="width: 100%;">
                <el-table-column align="center" label="投票项编号" prop="code"></el-table-column>
                <el-table-column align="center" label="图片集合">
                    <template slot-scope="scope">
                        <el-image fit="contain" class="image"
                            :preview-src-list="scope.row.imgs" :src="scope.row.imgs[0]"/>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="名称" prop="name"></el-table-column>
                <el-table-column align="center" label="投票项介绍" prop="content">
                    <template slot-scope="scope">
                        <img @click="clickImage(0,scope.row)" class="image" :src="scope.row.content" alt="">
                    </template>
                </el-table-column>
                <el-table-column align="center" label="浏览量" prop="cnt_look"></el-table-column>
                <el-table-column align="center" label="总投票量" prop="cnt_vote"></el-table-column>
                <el-table-column align="center" label="总投票人数" prop="cnt_people"></el-table-column>
                <el-table-column align="center" label="创建时间" prop="create_timex"></el-table-column>
                <el-table-column align="center" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button size="mini"
                        @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger"
                        @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="10"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>

        <!-- 添加 -->
        <el-dialog
            :before-close="handleCloseGift" 
            :close-on-click-modal="false"
            :visible.sync="isAddGift" 
            custom-class="dialog-wrap">
            <div v-if="editId<0" class="title">添加投票项</div>
            <div v-else class="title">编辑投票项</div>
            <div class="content-wrap" v-loading="addLoading">
                <el-form :model="giftForm" :rules="rulesGift" ref="giftForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="名称" prop="name">
                        <el-input v-model="giftForm.name" placeholder="请输入名称"></el-input>
                    </el-form-item>
                    <el-form-item label="图片集合" prop="imgs">
                        <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="10" :limit="5" :multiple="true" :isShopTip="false" :sizePx="true"
                                :btnDisplay="image.length<5" listType="picture-card"
                                v-model="image" :validWidth="1125" :validHeight="1200">
                            </aliyun-upload>
                            <div  class="image-wrap">
                                <div v-for="(item,index) in image" :key="index">
                                    <div v-if="image.length>0" class="fileList-box">
                                        <div @click="delImageList(index)" class="delFile">x</div>
                                        <el-image fit="contain" class="pic"
                                            :preview-src-list="image" :src="image[index]"/>
                                    </div>
                                </div>
                            </div>
                            <div>上传图片大小不超过10M ，分辨率为1125 * 1200</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="投票项介绍" prop="content">
                        <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="10" :limit="1" :isShopTip="false" :heightAuto="true"
                                :btnDisplay="content.length<1" listType="picture-card"
                                v-model="content" :validWidth="1125">
                            </aliyun-upload>
                            <div v-if="content.length>0" class="fileList-box">
                                <div @click="delContentList" class="delFile">x</div>
                                <el-image fit="contain" class="pic"
                                    :preview-src-list="content" :src="content[0]"/>
                            </div>
                            <div>上传图片大小不超过10M ，宽度必须为1125</div>
                        </div>
                    </el-form-item>
                    <div class="btn-wrap">
                        <el-button v-if="editId<0" :loading="btnLoading" type="primary" @click="submitForm">立即创建</el-button>
                        <el-button v-else :loading="btnLoading" type="primary" @click="submitFormEdit">保 存</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
        <div v-if="isShowImages">
            <el-image-viewer style="z-index: 99999"
            :on-close="closeViewer" 
            :url-list="imageList" />
        </div>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import AliyunUpload from "@/components/AliyunUpload.vue";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    components:{
        AliyunUpload,
        ElImageViewer
    },
    data () {
        return {
            loading: false,
            tableData: [],
            currentPage: 1,
            total: 0,
            isAddGift: false,
            addLoading: false,
            editId: '',
            giftForm: {
                vote_id: '',
                name: '',
                imgs: [],
                content: ''
            },
            rulesGift: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                content: [
                    { required: true, message: '请上传投票项介绍', trigger: 'blur' }
                ],
                imgs: [
                    { required: true, message: '请上传图片集合', trigger: 'blur' },
                ],
            },
            image: [],
            content: [],
            imageList: [],
            btnLoading: false,
            isShowImages: false,
            vote_id: '',
            editId: -1,
        }
    },
    watch: {
        image: {
            handler(newval, oldval) {
                if(newval.length > 0){
                    this.giftForm.imgs = JSON.stringify(newval)
                }
            },
            deep: true
        },
        content: {
            handler(newval, oldval) {
                if(newval.length > 0){
                    this.giftForm.content = newval[0]
                }
            },
            deep: true
        }
    },
    mounted () {
        this.vote_id = localStorage.getItem("singleId");
        this.giftForm.vote_id = this.vote_id
        this.getGiftList()
    },
    methods: {
        // 点击图片预览
        clickImage(index,row){
            this.imageList = []
            this.isShowImages = true;
            this.imageList.push(row.content);
        },
        // 关闭查看器
        closeViewer() {
            this.isShowImages = false
        },
        // 奖品列表
        getGiftList(){
            this.loading = true;
            let params = {
                page: this.currentPage,
                vote_id: this.vote_id,
                page_size: 10
            }
            common.connect("/rafflev1/vote/getVoteItemList",params,(res)=>{
                res.data.list.forEach(v => {
                    v.imgs = JSON.parse(v.imgs)
                });
                this.tableData = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            });
        },
        delImageList(index){
            this.image.splice(index,1)
        },
        delContentList(){
            this.content.splice(0,1)
        },
        // 添加
        submitForm(){
            this.$refs.giftForm.validate((valid) => {
                if (valid) {
                this.btnLoading = true;
                common.connect("/rafflev1/vote/setVoteItemAdd",this.giftForm,(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getGiftList();
                    this.image = [];
                    this.content = [];
                    this.$refs.giftForm.resetFields();
                    this.btnLoading = false;
                    this.isAddGift = false;
                });
                } else {
                console.log('error submit!!');
                return false;
                }
            });
        },
        // 编辑活动
        submitFormEdit(){
             this.$refs.giftForm.validate((valid) => {
                if (valid) {
                this.giftForm.id = this.editId
                this.btnLoading = true;
                common.connect("/rafflev1/vote/setVoteItemUpdate",this.giftForm,(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getGiftList();
                    this.image = [];
                    this.content = [];
                    this.editId = -1;
                    this.giftForm.name = '';
                    this.$refs.giftForm.resetFields();
                    this.btnLoading = false;
                    this.isAddGift = false;
                });
                } else {
                console.log('error submit!!');
                return false;
                }
            });
        },
        // 删除
        handleDelete(row){
            this.$confirm('此操作将永久删除该投票项, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params = {
                    vote_id: this.vote_id,
                    id: row.id
                }
                common.connect("/rafflev1/vote/setVoteItemDel",params,(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getGiftList();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 编辑
        handleEdit(row){
            this.editId = row.id;
            this.isAddGift = true;
            this.image = row.imgs;
            this.content[0] = row.content;
            this.giftForm.name = row.name;
            this.giftForm.imgs = row.imgs;
            this.giftForm.content = row.content;
        },
        handleCloseGift(){
            this.isAddGift = false;
            this.image = [];
            this.content = [];
            this.editId = -1;
            this.giftForm.name = '';
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getGiftList();
        },
        goBack(){
            this.$router.back() 
        }
    },
}
</script>

<style lang='scss'>
.info-wrap{
    background: rgb(240, 242, 245);
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 15px 25px;
    }
    .case-wrap{
        background: #fff;
        padding: 20px 20px 5px 20px;
        border-radius: 6px;
        position: relative;
        margin: 0 25px;
        .btn{
            margin-bottom: 15px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .image{
            height: 40px;
            vertical-align: bottom;
            cursor: pointer;
        }
    }
    .dialog-wrap{
        width: 750px;
        .title{
            font-size: 16px;
            color: #000;
            padding-bottom: 10px;
            margin-left: 20px;
            margin-top: -5px;
        }
        .content-wrap{
            max-height: 80vh;
            overflow-y: auto;
            padding-top: 20px;
            .el-input{
                width: 600px
            }
            .btn-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
            }
            .upload-wrap-content{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            .upload-wrap{
                padding-top: 2px;
                .image-wrap{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    width: 100%;
                }
                .fileList-box{
                    width: 100px;
                    height: auto;
                    position: relative;
                    margin-top: 10px;
                    margin-right: 10px;
                    .delFile{
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        border: 1px #ddd solid;
                        background: rgba(100, 100, 100, 0.5);
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 99;
                    }
                    .pic{
                        width: 100%;
                    }
                }
            }
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .pagination-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 5px;
    }
}
</style>
